exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-dsgvo-index-js": () => import("./../../../src/pages/dsgvo/index.js" /* webpackChunkName: "component---src-pages-dsgvo-index-js" */),
  "component---src-pages-imprint-index-js": () => import("./../../../src/pages/imprint/index.js" /* webpackChunkName: "component---src-pages-imprint-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-request-index-js": () => import("./../../../src/pages/request/index.js" /* webpackChunkName: "component---src-pages-request-index-js" */),
  "component---src-pages-service-and-prices-index-js": () => import("./../../../src/pages/service_and_prices/index.js" /* webpackChunkName: "component---src-pages-service-and-prices-index-js" */),
  "component---src-pages-under-construction-page-index-js": () => import("./../../../src/pages/underConstructionPage/index.js" /* webpackChunkName: "component---src-pages-under-construction-page-index-js" */),
  "component---src-pages-weddings-index-js": () => import("./../../../src/pages/weddings/index.js" /* webpackChunkName: "component---src-pages-weddings-index-js" */)
}

